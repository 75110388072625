import { template as template_184bfb84cb034ce0b7c30b802363741b } from "@ember/template-compiler";
const FKControlMenuContainer = template_184bfb84cb034ce0b7c30b802363741b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
