import { template as template_239be0530197469688f7754354fbc919 } from "@ember/template-compiler";
const FKLabel = template_239be0530197469688f7754354fbc919(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
