import { template as template_cf0452fc199141e1b145219c2bf08764 } from "@ember/template-compiler";
const FKText = template_cf0452fc199141e1b145219c2bf08764(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
