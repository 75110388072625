import { template as template_99318f315bc34eccb2632bd0224f9111 } from "@ember/template-compiler";
const SidebarSectionMessage = template_99318f315bc34eccb2632bd0224f9111(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
